import { useEffect, useState } from 'react'
import Head from 'next/head'
import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-phone-input-2/lib/style.css'
import 'react-image-lightbox/style.css' // Image Lightbox
import { AppProps } from 'next/app'
import { initGA, logPageView } from '../src/services/analytics'
import TagManager from 'react-gtm-module'
import { NextSeo } from 'next-seo'
import AppStyles, { GlobalStyle } from '../src/styles/global.styles'
import '../src/styles/tailwind.css'
import { cms } from '../src/services/api'
import { MenuContextProvider } from '../src/services/context/MenuContext'
import { ModalProvider } from 'styled-react-modal'
import { ModalBackdrop } from '../src/components/modal/Modal.styles'
import { Navigation } from '../src/components/navigation'
import {
  MenuAPIProps,
  SiteSettingProps,
  Link,
  SiteSettingPlusProps,
  LAYOUT_TEMPLATE
} from '../src/services/api/types'
import { Footer } from '../src/components/footer'
import {
  SiteContextProvider,
  SiteProps
} from '../src/services/context/SiteContext'
import { StructureData } from '../src/services/api/SEOHelper'
import {
  PAGE_TEMPLATE,
  YOU_FITNESS_TEMPLATE
} from '../src/services/api/constants'
config.autoAddCss = false // Tell Font Awesome to skip adding the CSS automatically since it's being imported above

interface PlusAppProps extends AppProps {
  navigationData: MenuAPIProps
  flatMenuData: MenuAPIProps
  siteData: {
    sitesettings: SiteSettingProps
    youplusfitnesssettings: SiteSettingPlusProps
  }
  siteInfors: SiteProps[]
  allSites?: SiteProps[]
  siteLinks: Link[]
}

export const App = (props: PlusAppProps) => {
  const {
    pageProps,
    Component,
    navigationData,
    flatMenuData,
    siteData,
    siteInfors,
    allSites,
    siteLinks
  } = props
  const [hasAnalytics, setAnalytics] = useState<boolean>(false)
  const [hasGTM, setGTM] = useState<boolean>(false)
  const [hasAdwords, setAdwords] = useState<boolean>(false)
  let SEO = StructureData.SEOMetaTags(siteInfors[0])
  const {
    google_analytics_code,
    google_tag_manager_code,
    google_adwords_code,
    microsoft_clarity_code,
    site_template
  } = siteData.sitesettings

  let template = ''
  let site_favicon = '/static/favicon.ico'

  if (
    pageProps &&
    pageProps.pageData &&
    pageProps.pageData.meta &&
    pageProps.pageData.meta.layout_template === YOU_FITNESS_TEMPLATE
  ) {
    template = PAGE_TEMPLATE.YOU_FITNESS
    site_favicon = '/static/you-fitness-favicon.ico'
  }

  if (
    site_template === PAGE_TEMPLATE.YOU_FITNESS &&
    (!pageProps ||
      (pageProps &&
        pageProps.pageData &&
        pageProps.pageData.meta &&
        pageProps.pageData.meta.type != 'gyms.Gym'))
  ) {
    if (pageProps.pageData.meta.layout_template === YOU_FITNESS_TEMPLATE) {
      template = PAGE_TEMPLATE.YOU_FITNESS
      site_favicon = '/static/you-fitness-favicon.ico'
    } else if (
      pageProps &&
      pageProps.pageData &&
      pageProps.pageData.meta &&
      pageProps.pageData.meta.type == 'gyms.Gym'
    ) {
    } else {
      template = site_template as LAYOUT_TEMPLATE
      if (site_template == 'you_fitness') {
        site_favicon = '/static/you-fitness-favicon.ico'
      }
    }
  }

  const getGymAdwordsCode = () => {
    if (
        pageProps &&
        pageProps.pageData &&
        pageProps.pageData.meta &&
        pageProps.pageData.meta.google_adwords_tracking_code
    ) {
      return pageProps.pageData.meta.google_adwords_tracking_code
    }
    return ''
  }

  const gymAdwordsCode = getGymAdwordsCode()
  const adwordsCode = gymAdwordsCode || google_adwords_code

  useEffect(() => {
    // google tag manager
    if (google_tag_manager_code && !hasGTM) {
      TagManager.initialize({ gtmId: google_tag_manager_code })
      setGTM(true)
    }
  })

  useEffect(() => {
    // google adwords
    if ((adwordsCode) && !hasAdwords) {
      initGA(adwordsCode)
      setAdwords(true)
    }
  })

  useEffect(() => {
    // google analytics
    if (google_analytics_code && !hasAnalytics) {
      console.log('init google analytics')
      initGA(google_analytics_code)
      setAnalytics(true)

      logPageView()
    }
  })

  return (
    <>
      <Head>
        <meta name='viewport' content='width=device-width, initial-scale=1'/>
        <meta httpEquiv='Content-Type' content='text/html; charset=utf-8'/>
        <link rel='stylesheet' href='https://use.typekit.net/izt0yag.css'/>
        <link rel='shortcut icon' href={site_favicon}/>
        {/* Google Adwords */}
        {(adwordsCode ) && (
            <>
              <script async src={`https://www.googletagmanager.com/gtag/js?id=${adwordsCode}`}></script>
              <script
                  dangerouslySetInnerHTML={{
                    __html: `window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${adwordsCode}');`
                  }}
              />
            </>
        )}

        {/* Google Analytics */}
        {google_analytics_code && (
            <>
              <script async src={`https://www.googletagmanager.com/gtag/js?id=${google_analytics_code}`}></script>
              <script
                  dangerouslySetInnerHTML={{
                    __html: `window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${google_analytics_code}');`
                  }}
              />
            </>
        )}

        {microsoft_clarity_code && (
            <script
                dangerouslySetInnerHTML={{
                  __html: `(function(c,l,a,r,i,t,y){
                    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                })(window, document, "clarity", "script", "${microsoft_clarity_code}");`
                }}
            />
        )}
      </Head>
      <AppStyles template={template as LAYOUT_TEMPLATE}>
        <ModalProvider backgroundComponent={ModalBackdrop}>
          <MenuContextProvider
            navigationData={Navigation.ApiBlockToProps(
              navigationData,
              siteData.sitesettings
            )}
            flatMenuData={Footer.ApiBlockToProps(
              flatMenuData,
              siteData.sitesettings,
              siteLinks
            )}
          >
            <SiteContextProvider
              Infor={siteInfors[0]}
              OtherSites={allSites || []}
              siteSettings={siteData.sitesettings}
              siteSettingPlus={siteData.youplusfitnesssettings}
            >
              <NextSeo {...SEO} />
              <Component {...pageProps} />
            </SiteContextProvider>
          </MenuContextProvider>
          <GlobalStyle />
        </ModalProvider>
      </AppStyles>
    </>
  )
}
App.getInitialProps = async (props: { Component: any; ctx: any }) => {
  const { Component, ctx } = props
  let pageProps = {}

  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx)
  }
  const [
    navigationData,
    flatMenuData,
    siteData,
    siteInfors,
    allSites
  ] = await Promise.all([
    cms.getNavigation(ctx),
    cms.getFlatMenu(ctx),
    cms.getSiteSettings(ctx),
    cms.getSiteInfors(ctx),
    cms.getSiteInfors(ctx, '?other')
  ])
  const siteLinks: Link[] =
    allSites &&
    allSites.length &&
    allSites
      .filter((item: SiteProps) => item.id != siteInfors[0].id)
      .map((site: SiteProps) => {
        return {
          label: site.site_name,
          url: site.root_url,
          target: '_blank'
        }
      })

  return {
    pageProps,
    navigationData,
    flatMenuData,
    siteData,
    siteInfors,
    siteLinks,
    allSites
  }
}
export default App
